.elementToFadeInAndOut {
  -webkit-animation: fadeinout 4s linear 1 forwards;
  animation: fadeinout 4s linear 1 forwards;
}

@-webkit-keyframes fadeinout {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.text-wrap-no-wrap {
  white-space: nowrap;
}


span.input-range__label.input-range__label--value{
  margin-left: 4px ;
}